body[data-color="grey"] #wrapper {
    background-color: #00152A;
}

body[data-color="grey"] #header {
    margin-left: 180px;
    background-color: #fff;
    height: 75px;
}

#header .search-form {
    padding-top: 8px;
}

#header .search-form input {
    width: 460px;
    height: 50px;
    border-radius: 5px;
    background-image: url('../img/search.png');
    background-repeat: no-repeat;
    background-position: 15px 17px;
    padding-left: 42px;
    padding-top: 7px;
}

#header .search-form input:focus {
    border: 1px solid #ccc !important;
}

body[data-color="grey"] #sidebar, #sidebar > ul {
    width: 180px;
}

#sidebar > ul ul li.active a:before, #sidebar > ul ul li.active a:after {
    left: 45px;
}

/** Content **/
#content {
    background-color: #F8F8F8 !important;
    min-height: 0;
}

#content-header h1 {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    line-height: 60px;
}

#content-header button.save, #content-header button.saved, #content-header a.new {
    margin-right: 15px;
}

#content-header button.save, #content-header a.new {
    background-color: #E5277D;
    border: 0;
    text-shadow: none;
}

/** Network 'n Settings **/
.network .row, .settings .row{
    border-top: 1px solid #ececec;
}

.network div.col-xs-12, .settings div.col-xs-12 {
    padding: 0px;
}

.network .network-item {
    background-color: white;
    padding-left: 35px;
    padding-top: 35px;
    padding-bottom: 35px;
}

.network .network-item.clickable:hover {
    background-color: #eee;
}

.network h3, .network p {
    padding: 0;
    margin: 0;
}

.network h3 {
    color: #000;
}

.network .network-item h3, .network p {
    color: #555;
}

.accent {
    color: #E3277C;
}

/** Menu **/
body[data-color="grey"] #sidebar > ul > li,
body[data-color="grey"] #sidebar > ul ul {
    background-color: #00152A;
    color: #fff;

}

body[data-color="grey"].flat #sidebar > ul > li.active {
    background-color: #011C39;
}

body[data-color="grey"].flat #sidebar > ul > li.active a,
body[data-color="grey"] #sidebar > ul > li:hover a,
body[data-color="grey"] #sidebar > ul > li.open.submenu a {
    background-color: #011C39;
    border-left: 5px solid #E3277C;
    color: #fff;
    cursor: pointer;
}

body[data-color="grey"] #sidebar > ul > li.open.submenu ul li a {
    border-left: none;
    padding-left: 58px;
    font-size: 10pt;
}

body[data-color="grey"] #sidebar > ul ul li a:hover, body[data-color="grey"] #sidebar > ul ul li.active a {
    background-color: #011C39;
    color: #fff;
}

body[data-color="grey"] #sidebar > ul ul li a,
body[data-color="grey"] #sidebar > ul > li > a {
    color: #fff;
}

#sidebar > ul > li.active > a {
    background-image: none !important;
}

#sidebar #logo {
    height: 150px;
    background-image: url('../img/logo.png');
    background-repeat: no-repeat;
    background-position-x: center;
}

#sidebar #footer-menu {
    position: fixed;
    bottom: 0px;
    border-top: 1px solid #192C3D;
}

#sidebar #footer-menu li, #sidebar #footer-menu li a {
    height: 65px;
}

#sidebar #footer-menu li a {
    line-height: 50px;
}

/** User menu **/
#user-nav {
    height: 75px !important;
    padding-top: 14px;
    right: 20px;
}

#user-nav > ul > li > a, #user-nav > ul > li > a > i {
    color: #000;
}

body[data-color="grey"] #user-nav > ul, body[data-color="grey"] #user-nav > ul > li {
    border: 0px;
    margin: 0px;
    padding: 0px;
}

#user-nav > ul > li:hover > a, #user-nav > ul > li:hover > a > i, #user-nav > ul > li.open > a, #user-nav > ul > li.open > a > i {
    color: #000;
}

.nav > li > a:hover, .nav > li > a:focus {
    background-color: #fff;
}

#user-nav > ul > li > a {
    font-size: 11pt;
    padding: 0;
    position: inherit;
    display: inline;
}

.nav-user-photo {
    float: left;
    margin-right: 3px;
    border: 1px solid #e8e8e8;
}

#user-nav > ul > li > a {
    background-image: url('../img/user-menu.png');
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    float: left;
    margin-top: 12px;
}

#user-nav .dropdown-menu {
    width: 180px;
    right: -6px;
    left: inherit;
    margin-top: 10px;
}

#user-nav .dropdown-menu:before {
    right: 9px;
    left: inherit;
}

#user-nav .dropdown-menu:after {
    right: 10px;
    left: inherit;
}

body[data-color="grey"] #user-nav > ul > li.username {
    margin-right: 10px;
    color: #b2b2b2;
}

#user-nav > ul > li.username > span.firstname {
    font-size: 9pt;
    height: 18px;
    display: block;
    text-align: right;
}

#user-nav > ul > li.username > span.lastname {
    font-size: 9pt;
    height: 18px;
    display: block;
    text-align: right;
}

input.ng-invalid {
    border: 1px solid #B94A48;
}

#breadcrumb a.current {
    background-image: none;
}

#footer {
    height: 65px;
    border-top: 1px solid #ececec;
    background-color: #fff;
    position: fixed;
    left: 180px;
    bottom: 0px;
    z-index: 16;
    padding: 0;
    padding-left: 25px;
    width: 100%;
}

#footer div.tip {
    height: 65px;
    line-height: 65px;
    color: #464646;
}

#footer div.tip span {
    text-shadow: none !important;
}

#footer div.tip img {
    padding-bottom: 3px;
}

#footer button.help {
    color: #fff;
    background-color: #e5277d;
    position: fixed;
    right: 20px;
    bottom: 14px;
}

@media (max-width: 991px) and (min-width: 768px) {
    body[data-color="grey"] #sidebar, #sidebar > ul {
        /*width: 48px;*/
    }

    body[data-color="grey"] #content {
        /*margin-left: 48px;*/
    }

    body[data-color="grey"] #header {
        /*margin-left: 48px;*/
    }

    #user-nav {
        padding-top: 7px;
    }

    #sidebar #logo {
        background-image: url('../img/logo-min.png');
    }
}

@media (max-width: 767px) {
    body[data-color="grey"] #header {
        margin-left: 0px;
    }

    body[data-color="grey"] #content {
        margin-top: 0px;
    }

    #user-nav {
        height: auto !important;
        padding-top: 0px;
        position: absolute;
        top: 20px;
        right: 20px;
        left: inherit;
    }

    .menu-open #content, .menu-open #header {
        left: 180px;
    }
}

.btn-pink, .btn-pink[disabled] {
    background-color: #e5277d;
    border-color: #d02762;
}

.btn-pink:hover {
    background-color: #d02762;
    border-color: #e5277d;
}

.btn-dark-blue {
    background-color: #5C9AD7;
    border-color: #5787bf;
}

.btn-dar-blue:hover {
    background-color: #5787bf;
    border-color: #5C9AD7;
}

h3 {
    color: #E3277C;
}

body {
    background: #F8F8F8;
}

.calendar {
    background: #fff;
    margin-top: 30px;
}

.calendar .header {
    background: #014B94;
    height: 55px;
    color: #fff;
    cursor: pointer;
    line-height: 55px;
}

.calendar .header h3 {
    font-size: 12pt;
}

.calendar .header .label {
    background: #E4267C;
}

.calendar .header .weeks {
    line-height: 55px;
}

.calendar .weeks .week {
    text-align: center;
}

/* Clear/Fix, make this more cooler */
.city.disabled .header {
    background: #ECECEC;
    color: #000;
}

.city.disabled .details {
    display: none;
}

.calendar .header button.button-browse-week {
    height: 45px;
    width: 35px;
    margin-top: 5px;
    background: #013C76;
    border: 0;
    color: #fff;
}

.calendar .series {
    border-left: 1px solid #ECECEC;
    border-right: 1px solid #ECECEC;
    border-bottom: 1px solid #ECECEC;
}

.calendar .series .serie {
    border-top: 1px solid #ECECEC;
    clear: both;
}

.calendar .series .serie .details h4 {
    font-size: 11pt;
}

.calendar .series .details .label {
    background: #EDEDED;
    color: #000;
}

.calendar .series .weeks .week {
    padding-top: 5px;
    padding-bottom: 5px;
}

.calendar .series .weeks .week .item {
    height: 150px;
    border: 1px #EDEDED dashed;
    border-radius: 5px;
}

.calendar .series .weeks .week .item-empty:hover {
}

.calendar .series .weeks .week .item-planned {
    background: #ECECEC;
    border: 0;
}

.calendar .series .weeks .week .item-orange {
    border-left: 6px solid #E36A01;
}

.calendar .series .weeks .week .item-blue {
    border-left: 6px solid #1E68B1;
}

.calendar .series .weeks .week .item-green {
    border-left: 6px solid #009D66;
}

.calendar .series .weeks .week .item-pink {
    border-left: 6px solid #E5277D;
}

.calendar .series .weeks .week .item span.title {
    font-size: 10pt;
    padding-top: 10px;
    padding-left: 10px;
    display: block;
}

.calendar .series .weeks .week .item button.delete {
    position: absolute;
    bottom: 9px;
    right: 20px;
}

.calendar div.addLocation {
    height: 100px;
    border: 1px solid #ECECEC;
    margin-bottom: 100px;
    padding-top: 30px;
}

.list-filters {
    float: right;
    margin-right: 30px;
}

.organisations-list, .series-list {
    margin-bottom: 100px;
}

/*.organisations-list, .serie-objects .objects, .serie-objects .available-objects, .series-list {*/
    /*border-top: 1px solid #ECECEC;*/
    /*border-left: 1px solid #ECECEC;*/
    /*border-right: 1px solid #ECECEC;*/
/*}*/

.organisations-list .loading, .series-list .loading, .packages-list .loading {
    margin-left: auto;
    margin-right: auto;
    width: 800px;
    text-align: center;
    margin-top: 40px;
}

.organisations-list .organisation, .series-list .serie, .packages-list .defect {
    border-bottom: 1px solid #EFEEEF;
}

.organisations-list .organisation .header, .serie-objects .object, .series-list .serie, .packages-list .defect{
    background: #fff;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
}

.organisations-list .organisation.even .header {
    background: #eee;
}

.organisations-list .organisation .details-search {
    height: 40px;
    line-height: 40px;
}

.organisations-list .organisation .details-search input, .organisations-list .organisation .details-search select {
    margin-top: 5px;
}

.organisations-list .organisation .details-header, .serie-objects .details-header{
    background: #ECECEC;
    height: 60px;
    line-height: 60px;
}

.organisations-list .organisation .objects, .organisations-list .organisation .series {
    max-height: 360px;
    overflow: scroll;
}

.organisations-list .organisation .objects .object, .organisations-list .organisation .series .serie {
    height: 60px;
    line-height: 60px;
    background: #fff;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.organisations-list .organisation .objects .object.selected{
    height: 280px;
    cursor: default;
}

.organisations-list .organisation .object-form {
    background-color: #eee;
    padding-top: 10px;
}

.organisations-list .organisation .object-form label {
    color: #999999;
    font-size: 10pt;
    font-weight: normal;
}

.organisations-list .organisation .object-form button, .organisations-list .organisation .object-form-closed button {
    background: #e5277d; color: #fff;
}

.organisations-list .organisation .object-form-closed {
    height: 120px;
    background-color: #fff;
    line-height: 120px;
    text-align: center;
}

.serie-objects .objects {
    background: white;
    height: 180px;
    overflow: scroll;
}

.serie-objects .objects .object, .serie-objects .available-objects .object {
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #EFEEEF;
}

.serie-objects .objects .object.selected, .serie-objects .available-objects .object.selected {
    background: #014B94;
    color: #fff;
}

tr.selected td {
    background: #014B94 !important;
    color: #fff;
}

.serie-objects .buttons {
    text-align: center;
    height: 60px;
    line-height: 60px;
}

ul.weekList {
    padding: 0px;
}

ul.weekList li.campaign {
    display: block;
    background: white;
    border: 1px solid #d9d9d9;
    padding: 12px 8px 12px;
    cursor: pointer;
    width:50%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:10px;
}

ul.scrollList {
    padding: 0px;
}

ul.scrollList li.scroller {
    display: block;
    border: 1px dashed #d9d9d9;
    padding: 0px 8px 0px;
    margin-bottom: 10px;
    text-align: center;
    height: 80px;
    vertical-align: middle;
}

ul.scrollList li.scroller div {
    width: 100%;
    height: 100%;
    padding-top:35px;
}

ul.scrollList li.scroller div.scroll {
    display: block;
    background: white;
    border: 1px solid #d9d9d9;
    padding: 12px 8px 12px;
    cursor: pointer;
    width:50%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}