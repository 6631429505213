.col-xs-12 {
    padding-left: 5px;
    padding-right: 5px;
}

.campaignPlanner {
    margin: 0 -15px;
}

img {
    image-orientation: from-image;
}

.btn-pink {
    background-color: #e5277d
}

table.calendar.table {
    margin: 0 0 -1px 0;
}

/* reset unicorn theme */
table.table th {
    border-radius: 0;
}

.widget-box .widget-content {
    padding-bottom: 40px;
}

body[data-color="grey"].flat .calendar-header th {
    padding: 0 15px;
    background-color: #F1F1F1;
    font-size: 15px;
    line-height: 14px;
    color: #444;
}

.fa.pink {
    color: #e5277d;
}

.calendar-header .tooltip-inner, .heatmap-list .tooltip-inner {
    max-width: 450px;
}

body[data-color="grey"].flat .calendar-header.open th {
    line-height: 24px;
    background-color: #FFF;
}

body[data-color="grey"].flat .calendar-header.disabled th {
    line-height: 48px;
    background-color: #F6F6F6;
    color: #666;
}

body[data-color="grey"].flat .calendar-header.disabled th:hover {
    background: #F1F1F1;
    color: #444;
    cursor: pointer;
}

.calendar-header-controls > span {
    float: right;
    padding: 0 5px;
    font-size: 18px;
    color: #666;
}

.calendar-header-controls > span.badge {
    color: #fff;
    font-size: 12px;
    padding: 3px 5px 2px;
    margin-top: 16px;
    margin-right: 3px;
}

.calendar-header-controls.open > .fa-chevron-circle-left,
.calendar-header-controls.open > .fa-chevron-circle-right {
    font-size: 20px;
}

.calendar-header-controls span i:hover {
    color: #444;
    cursor: pointer;
}

table.calendar.table th:first-child {
    font-weight: bolder;
    text-align: left;
}

table.calendar.table tr.calendar-info th {
    color: #999;
}

table.calendar.table tr.calendar-info th:first-child {
    font-size: 13px;
    color: #666;
}

table.calendar.table tr.week td dl {
    margin: 4px 16px 12px 10px;
}

/* Buttons */
table.calendar.table th:last-child {
    width: 180px;
}

table.calendar.table th:last-child button {
    margin-right: 2px;
}

table.calendar.table tbody td {
    padding: 4px;
}

td.week-column {

}

/* Twitter bootstrap extend */
.badge-pink {
    background-color: #e5277d;
    border-color: #d02762;
}

/* Calendar items */
div.item {
    position: relative;
    margin: 0;
    padding: 2px 4px;
    background-color: #efefef;
    border-radius: 6px;
    border: 1px solid #ddd;
    min-height: 50px;
}

div.item h4 {
    margin: 4px 0 6px 0;
    font-size: 14px;
}

/* Various calendar item colors */
div.item-blue {
    border-left: 6px solid #2980b9;
}

div.item-green {
    border-left: 6px solid #27ae60;
}

div.item-pink {
    border-left: 6px solid #d02762;
}

div.item-lightblue {
    border-left: 6px solid #BACEE2;
}

.btn-default {
    background-color: #efefef;
}

div.item-package {
    background-color: #5C9AD7;
    color: #fff;
}

div.item-package select {
    color: #000;
}

div.item-weekOfChange {
    position: relative;
}

div.planner-hoverable:hover {
    cursor: pointer;
    background: #eee;
}

div.item-weekOfChange > .dashed:before {
    position: absolute;
    height: 20px;
    font-family: FontAwesome;
    color: #666;
    bottom: 0;
    left: 8px;
    content: '\f0d1';
    z-index: 10;
}

.btn-pink:focus,
.btn-pink.active {
    color: #ffffff;
    background-color: #e5277d;
    border-color: #d02762;
}

div.item a {
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
}

div.item a.ico-green {
    color: darkgreen;
    filter: alpha(opacity=40);
    opacity: .4;
}

div.item a.ico-green:hover {
    color: darkgreen;
    filter: alpha(opacity=80);
    opacity: .8;
}

div.item a:hover {
    filter: alpha(opacity=60);
    opacity: .6;
}

div.item.copying {
    background-color: lightgoldenrodyellow;
}

div.item .fa {
    opacity: .8;
    cursor: pointer;
    font-size: 12px;
    width: 12px;
}

div.item .fa:hover {
    /*opacity: 1;*/
}

.fa.disabled, div.item-package select.disabled {
    opacity: .6;
    cursor: default !important;
}

div.item .fa.disabled:hover {
    /*opacity: .6;*/
}

div.item .fa-lock, div.item .fa-unlock {
    font-size: 16px;
}

.ico-green {
    color: green !important;
    pointer: default !important;
}

.ico-pink {
    color: #e5277d !important;
    pointer: default !important;
}

.ico-green.disabled, .ico-pink.disabled {
    opacity: .6;
}

.hover-ico:hover {
    color: #0048cd;
}

.hover-ico.disabled:hover {
    color: rgb(187, 187, 187);
}

div.item-package .close,
div.item-package a {
    color: #fff;
    text-shadow: 0 1px 0 #666;
    filter: alpha(opacity=50);
    opacity: .5;
}

div.item-package .close:hover,
div.item-package a:hover {
    filter: alpha(opacity=80);
    opacity: .8;
}

.pull-right-icon {
    float: right;
    margin-left: 3px;
}

.popup-textarea {
    position: absolute;
    padding: 6px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 380px;
    z-index: 9999;
}

.popup-textarea label {
    line-height: 24px;
    font-weight: normal;
    margin-left: 2px;
}

.popup-textarea textarea {
    padding: 4px;
    margin-bottom: 6px;
}

.popup-textarea.planning {
    bottom: 24px;
}

div.dashed {
    border: 1px dashed #999;
    display: block;
    height: 50px;
}

/**
 * Object form
 **/
.form-row {
    margin-bottom: 12px !important;
}

.form-row h5 {
    line-height: 36px;
}

.form-row-label {
    line-height: 24px;
}

.form-row-label label {
    font-weight: normal;
}

.container-full {
    margin: 0 auto;
    width: 100%;
}

#series-detail {
    margin-bottom: 20px;
}

#series-detail h4 {
    font-weight: normal;
    padding: 5px;
    margin-bottom: 15px;
}

/* reset twitter bootstrap table stuff */

.table.table-plain tbody tr td {
    border: 0;
}

.table.table-plain tbody tr td:first-child {
    width: 50%;
    font-weight: bolder;
}

/**
 * Typography
 */
.widget-content h3 {
    margin-top: 10px;
    font-weight: normal;
    font-size: 18px;
    color: #E3277C;
}

/**
 * Form stuff
 **/
.form-group {
    border: 0;
}

.form-group.margin-top-20,
.margin-top-20 {
    margin-top: 20px;
}
.margin-top-5 {
    margin-top: 5px;
}

.form-horizontal .control-label {
    font-weight: normal;
    text-align: left;
}

/**
 * ICONS
 **/

.cc-icon-warning {
    color: #d02762;
    font-size: 1.2em;
}

.cc-icon-delete {
    color: #333;
    font-size: 1.2em;
    cursor: pointer;
}

.cc-icon-copy {
    color: #333;
    font-size: 1.2em;
    margin-right: 5px;
    cursor: pointer;
}

.cc-icon-delete:hover, .cc-icon-copy:hover {
    color: #D02762;
}

/* default CentercomConnect table (resets some TB and theme crap */
.table.table-striped tbody > tr:nth-child(2n+1) > th,
.table.table-striped tbody > tr:nth-child(2n+1) > td {
    background-color: #f6f6f6;
}

.table.table-striped tbody > tr:nth-child(2n+1).danger > th,
.table.table-striped tbody > tr:nth-child(2n+1).danger > td {
    background-color: #f2dede;
}

.table.table-striped tbody > tr.deleted > th,
.table.table-striped tbody > tr.deleted > td {
    text-decoration: line-through;
}

.table.table-striped tbody > tr.deleted > .buttons {
    text-decoration: none !important;
}

.cc-table {
}

/* unicorn theme reset */
.cc-table.table th {
    font-size: 12px;
    text-align: left;
}

.cc-table.table th.text-center {
    text-align: center;
}

.cc-table.table td {
    padding: 12px 8px;
    background-color: #fff;
}

.cc-table.table tr:last-child td {
    /*border-bottom: 1px solid #ccc;*/
}

/* twitter bootstrap  reset */
.cc-table.table thead tr th {
    background: #fff !important;
    padding: 12px 8px;
    border-bottom-width: 1px;
    font-weight: normal;
}

.cc-table.table .center {
    text-align: center;
}

.cc-table.table td {
    vertical-align: middle;
    border-left: 0;
    border-right: 0;
}

.heat-org {
    font-weight: bold;
    color: #666;
    width: 15%;
}

.heatmap-list .previous-week:hover, .heatmap-list .next-week:hover {
    color: #e5277d;
    cursor: pointer;
}

.heat-cell {
    position: relative;
    padding: 0 !important;
    text-align: center;
    cursor: pointer;
    /*color: #428bca;*/
    color: #333;
}

.heat-cell:hover {
    text-decoration: underline;
}

.heat-cell-div {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 40px;
}

.heat-badge {
    position: absolute;
    right: 0;
    /*border: 1px solid black;*/
    height: 16px;
    width: 16px;
    color: black;
    line-height: 16px;
}

.heat-badge-top {
    top: 0;
}

.heat-badge-bottom {
    bottom: -1px;
}

/*.heat-badge-left {*/
/*border-right: 1px solid white;*/
/*}*/
/*.heat-badge-right {*/
/*float: right;*/
/*border-left: 1px solid white;*/
/*}*/

.paginator {
    font-weight: normal;
    color: #428bca !important;
}

.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
    border-bottom: 0;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
    display: none;
}

.tab-content > .active,
.pill-content > .active {
    display: block;
}

.tabs-below > .nav-tabs {
    border-top: 1px solid #ddd;
}

.tabs-below > .nav-tabs > li {
    margin-top: -1px;
    margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}

.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
    border-top-color: #ddd;
    border-bottom-color: transparent;
}

.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
    border-color: transparent #ddd #ddd #ddd;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
    float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
    min-width: 74px;
    margin-right: 0;
    margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
    float: left;
    margin-right: 19px;
    border-right: 1px solid #ddd;
}

.tabs-left > .nav-tabs > li > a {
    margin-right: -1px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
    border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
    border-color: #ddd transparent #ddd #ddd;
    *border-right-color: #ffffff;
}

.tabs-right > .nav-tabs {
    float: right;
    margin-left: 19px;
    border-left: 1px solid #ddd;
}

.tabs-right > .nav-tabs > li > a {
    margin-left: -1px;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}

.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
    border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
    border-color: #ddd #ddd #ddd transparent;
    *border-left-color: #ffffff;
}

.angular-google-map-container {
    height: 400px;
}

.ngdialog.ngdialogBig .ngdialog-content {
    width: 90% !important
}

button.ngdialog-button:disabled {
    opacity: 0.3 !important;
    cursor: default !important;
}

/**
 * ORDERLINES
 */

.orderlines table thead tr th {
    border: none;
    background: none !important;
}

.orderlines-header, .materials-header {
    background-color: #F6F6F6;
    border: 1px solid #CDCDCD;
    height: 42px;
    display: block;
    padding: 0 20px;
}

.orderlines-header, .materials-header {
    background-color: #F6F6F6;
    color: #666;
    cursor: pointer;
}

.orderlines-header:hover, .materials-header:hover {
    background-color: #F1F1F1;
    color: #444;
}

.orderlines-header h4, .materials-header h4 {
    font-size: 14px;
    color: #717171;
    line-height: 20px;
}

.orderlines-header-controls, .materials-header-controls {
    text-align: center;
    padding: 9px 0;
}

.orderlines-header-controls a, .materials-header-controls a {
    color: #717171;
    font-size: 18px;

}

.orderlines-body, .materials-body {
    display: inline-block;
    border: 1px solid #CDCDCD;
    border-top: none;
    margin-top: -2px;
    width: 100%;
}

.orderlines-body table th, .materials-body table th {
    text-align: left;
    font-size: 12px;
    border-top: 0;
}

.orderlines .fa {
    cursor: pointer;
    color: #1e2a37;
    line-height: 31px;
    padding-left: 5px;
    font-size: 16px;
    vertical-align: baseline;
}

.orderlines .fa-trash-o:hover {
    color: #c03432;
}

.orderlines .fa-save:hover {
    color: #006600;
}

.orderlines .input-group {
    padding: 0;
}

.orderline-amount .fa {
    line-height: 0;
    font-size: 12px;
    padding: 0;
}

.orderline-amount-credit .fa {
    color: #b93533;
}

.orderline-amount-debit .fa {
    color: #186409;
}

tr.week .pink {
    color: #e5277d;
}

.materials .fa {
    cursor: pointer;
    color: #1e2a37;
    line-height: 31px;
    padding-left: 5px;
    font-size: 16px;
    vertical-align: baseline;
}

.materials .fa-trash-o:hover {
    color: #c03432;
}

.materials .fa-save:hover {
    color: #006600;
}

.materials .fa-plus:hover {
    color: #006600;
}

.materials .red {
    color: #c03432
}

.materials .green {
    color: #006600;
}

.materials .materialscell {
    padding: 6px 10px;
    display: inline-block;
}

.redtab {
    color: #c03432 !important;
}

.greentab {
    color: #006600 !important;
}

.campaignPlanner .week .input-sm {
    padding: 1px 2px;
}

.campaignPlanner span.notAvailable {
    position: relative;
    left: -5px;
    top: 10px;
    text-decoration: underline;
}

.campaignPlanner span.dateOfChange {
    display: inline;
    position: relative;
    left: 25px;
    bottom: -30px;
    float: left;
}

.planning-option .item:hover {
    cursor: pointer;
    background-color: #ddd;
}

.planning-option .item > p {
    padding: 0;
    margin: 0;
}

.ccid-search > div {
    margin: -8px 15px;
}

.ccid-search.not-found {
    -webkit-animation: shake .6s linear;
}

.ccid-search .btn.saved {
    color: green;
}

@-webkit-keyframes shake {
    0% {
        left: -10px;
    }
    16% {
        left: 9px;
    }
    33% {
        left: -6px;
    }
    50% {
        left: 5px;
    }
    66% {
        left: -2px;
    }
    83% {
        left: 1px;
    }
    100% {
        left: 0px;
    }
}

.nav-tabs > li > a, .nav-tabs > li > a:hover {
    background: #F9F9F9 !important;
    cursor: pointer;
    color: #555;
}

.nav-tabs > li.active {
    background-color: #FFFFFF !important;
}

.nav-tabs > li.active a {
    color: #e5277d !important;
}

.nav-tabs {
    border-bottom: 0px;
}

.planner-detail h5 {
    font-size: 15px;
    margin-bottom: 20px;
}

.planner-detail .row {
    margin: 0 -15px;
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
}

.serie-detail .title > h3, .campaign-detail .title > h3, .organisation-detail .title > h3 {
    color: #000000;
    font-size: 24px;
    padding: 0 0px;
    margin: 0px 6px -5px 3px;
}

.serie-detail h4, .campaign-detail h4, .organisation-detail h4 {
    font-weight: 600;
    padding-top: 10px;
}

.serie-detail .table, .campaign-detail .table, .organisation-detail .table {
    margin-bottom: 0;
}

.serie-detail .table.table-plain tbody tr td, .campaign-detail .table.table-plain tbody tr td, .organisation-detail .table.table-plain tbody tr td {
    padding-left: 0;
}

.serie-detail #object-overview {
    max-height: 500px;
    overflow: auto;
}

.serie-detail #object-overview tr th {
    border: none;
    background: none !important;
    font-size: 13px;
    text-align: left;
}

.serie-detail #serie-rates tr th {
    border: none;
    background: none !important;
    font-size: 13px;
    text-align: left;
}

.card-detail-link {
    cursor: pointer;
}

.campaign-detail #information, .campaign-detail #relation {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

i.fa.fa-list.campaign-planner-list-toggler {
    font-size: 24px;
    top: 5px;
    right: 5px;
    position: relative;
    margin-right: 12px;
    cursor: pointer;
}

.campaign-planner-list {
    margin: -12px -15px;
}

.campaign-planner-list #series, .campaign-planner-list #options {
    margin-bottom: 0;
}

.campaign-planner-list #series > thead .fa, .campaign-planner-list #options > thead .fa {
    color: #717171;
    font-size: 18px;
}

.campaign-planner-list .fa {
    font-size: 16px;
    color: #333333;
}

.campaign-planner-list .fa.clickable:hover {
    color: #000000;
}

.campaign-planner-list td {
    vertical-align: middle !important;
    line-height: 30px !important;
}

.campaign-planner-list .fa-trash-o:hover, .slider-arrows .fa-trash-o:hover {
    color: red !important;
}

.card-detail-link:hover, .campaign-planner-list-toggler.active {
    color: #e5277d;
}

.hidden {
    opacity: 0;
}

.no-gutter > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

#comments {
    padding: 0;
    list-style: none;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
}

#comments li {
    margin: 10px 0 0;
}

#comments .commenter-image {
    display: table-cell;
    height: 100%;
    width: 50px;
    padding-right: 10px;
}

#comments .commenter-text {
    display: table-cell;
    position: relative;
    top: -10px;
}

#comments .sub-text {
    color: #aaa;
    font-size: 11px;
}

#comments p {
    margin: 0;
    padding: 0;
}

#comments .commenter-image > img {
    width: 100%;
    border-radius: 50%;
}

#comments::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

.heatmap-list table thead tr th {
    padding: 5px 8px !important;
    font-weight: 600 !important;
}

.organisation-detail .table tr td {
    padding: 2px 0;
}

.navigation {
    list-style: none;
}

.navigation a:hover {
    cursor: pointer;
    color: #e5277d;
}

.slider-arrows a {
    font-size: 24px;
}

.slider-arrows a span:hover {
    cursor: pointer;
}

.slider-arrows .delete-button {
    display:inline-block;
}

.slider-arrows .arrow-buttons {
    display: inline-block;
    float: right;
    font-size: 36px;
}

.slide.ng-hide-add.ng-hide-add-active,
.slide.ng-hide-remove {
    opacity: 0;
}

.slide.ng-hide-add,
.slide.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
}

.slide {
    min-height: 350px;
}

.slider img {
    max-width: 100%;
    max-height: 100%;
    margin:0 auto;
    display: block;
}

.pre {
    white-space: pre-wrap;
}

.floating-week-navigator {
    height: 36px;
    width: 64px;
    display: block;
    position: fixed;
    top: 20px;
    right: 75px;
    margin: 5px;
    padding: 6px !important;
    border: 1px solid #969696;
    border-radius: 8px;
    background-color: lightgray;
}

.floating-week-navigator .fa {
    color: #e5277d;
    font-size: 24px;
}

.slider-arrows .fa-trash-o {
    color: #000000;
}

.materials input {
    padding-left: 2px;
    padding-right: 2px;
}

#object-overview thead th {
    text-align: left;
}

.campaign-photos p {
    text-align: center;
    font-size: 14px;
    margin: 0;
}

.campaign-photos card, .campaign-photos h4 {
    width: 100%;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    height: 35px;
    padding: 10px 0;
}

/**
    Pagination buttons
 */
.pagination-buttons {
    float: right;
}

.pagination-buttons button {
    background-color: white;
    border: 1px solid lightgrey;
    margin: 1px;
    min-width: 30px;
    height: 30px;
}

.pagination-buttons button:disabled {
    background-color: lightgrey;
    color: white;
}

.pagination-buttons button.current,
.pagination-buttons button:not(:disabled):hover {
    background-color: #E3277C;
    color: white;
}

/**
    Inspection list colours
 */
.inspection-list {
    margin-bottom: 0;
}
.inspection-list .centercom.new td {
    background-color: #f2dede;
}
.inspection-list .centercom.awaiting_cc td,
.inspection-list .centercom.accepted td,
.inspection-list .centercom.done.invoiceable td {
    background-color: #faebcc;
}
.inspection-list .centercom.done td {
    background-color: #dff0d8;
}

.inspection-list .distributor.assigned td {
    background-color: #f2dede;
}
.inspection-list .distributor.awaiting_distributor td {
    background-color: #faebcc;
}
.inspection-list .distributor.done td {
    background-color: #dff0d8;
}

.button-margin {
    margin-left: 10px;
}

/**
    Checkbox style
 */
.pretty-checkbox {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.pretty-checkbox label {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    background: #fff;
    border: 1px solid lightgrey;
}

.pretty-checkbox label:after {
    content: '';
    width: 17px;
    height: 9px;
    position: absolute;
    top: 3px;
    left: 2px;
    border-left: 3px solid #333;
    border-bottom: 3px solid #333;
    background: transparent;
    opacity: 0;
    transform: rotate(-45deg);
}

.pretty-checkbox label:hover::after {
    opacity: 0.5;
}

.pretty-checkbox input[type=checkbox] {
    visibility: hidden;
}

.pretty-checkbox input[type=checkbox]:checked + label:after {
    opacity: 1;
}

.cc-table.table .pretty-checkbox-cell {
    width: 40px;
    height: 40px;
    padding: 0;
    vertical-align: top;
    position: relative;
}

/**
    Tags readonly field
    Styling mostly copied from ng-tags-input.min.css
 */
.tags {
    -webkit-appearance: textfield;
    padding: 1px;
    overflow: hidden;
    word-wrap: break-word;
    border: 1px solid #a9a9a9;
    box-shadow: 1px 1px 1px 0 #d3d3d3 inset;
    height: 100%;
    min-height: 34px;

    cursor: not-allowed;
    background-color: #eee;
}

.tags .tags-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.tags  .tags-list li {
    margin: 2px;
    padding: 0 5px;
    display: inline-block;
    float: left;
    font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
    height: 26px;
    line-height: 25px;
    border: 1px solid #acacac;
    border-radius: 3px;
    background: -webkit-linear-gradient(top, #f0f9ff 0, #cbebff 47%, #a1dbff 100%);
    background: linear-gradient(to bottom, #f0f9ff 0, #cbebff 47%, #a1dbff 100%);
}

.dt-row:hover {
    cursor: pointer;
}

/**
 * Inspection
 */
.inspection-status {
    margin-right: 5px;
}

.inspection-details .table > tbody {
    border-top: 0;
}

.inspection-details .table td {
    font-size: 14px;
    vertical-align: middle;
}

.inspection-details textarea {
    padding: 5px;
    width: 100%;
    height: 100%;
    min-height: 100px;
    resize: none;
}

.inspection-details textarea[disabled="disabled"] {
    background-color: #eee;
    color: #777777;
    cursor: not-allowed;
    border: none;
}

.inspection-buttons .btn {
    margin-left: 5px;
    margin-bottom: 15px;
    height: 100%;
}

.inspection-buttons h4 {
    margin: 0 5px;
    line-height: 34px;
}

.inspection-buttons .dropdown-menu {
    top: 34px;
    left: 2px;
}

.inspection-details .delete-image {
    position: absolute;
    left: 142px;
    top: 0;
}

.inspection-details .image-gallery {
    display: flex;
    flex-flow: row wrap;
    max-height: 350px;
    overflow-y: scroll;
}

.inspection-details .image-gallery .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: auto;
    max-width: calc(25% - 10px);
    width: calc(25% - 10px);
    height: 200px;
    position: relative;
    margin: 5px;
}

.inspection-details .image-gallery .image:hover {
    cursor: pointer;
}

.inspection-details .image-gallery .image .delete-button .fa {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 20px);
    color: #ffffff;
    font-size: 50px;
    opacity: 0.6;
}

.inspection-details .image-gallery .image .delete-button .fa:hover {
    opacity: 1;
}

.inspection-details .image-gallery .image .delete-button {
    height: calc(100%);
    width: calc(100%);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
}

.inspection-details table td {
    vertical-align: middle;
}


/**
 * Object Map
 */

.object-map {
    padding: 5px;
}

.object-map .table td {
    font-size: 14px;
    vertical-align: middle;
}

.object-coordinate-changer {
    margin-left: 20px;
}

.object-coordinate-changer img {
    margin-right: 10px;
}

.object-coordinate-changer p {
    font-size: 14px;
    margin-bottom: 20px;
}

.object-coordinate-changer .fa {
    cursor: pointer;
    opacity: 0.8;
    margin-right: 15px;
    font-size: 17px;
}

.object-coordinate-changer .fa:hover {
    opacity: 1.0;
}

/**
 * qq Fine uploader
 */
.qq-thumbnail-selector {
    min-width: 50px;
}

.qq-upload-button {
    margin-left: 5px;
}

.qq-upload-list .qq-upload-success {
    display: none;
}

.widget-box .widget-title .label, .widget-box .widget-title span.badge.badge-pink.ng-binding.badge {
    margin-top: 0;
}

.bootbox.modal {
    z-index: 1050 !important;
}

html, body {
    height: 100%;
}
